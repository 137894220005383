<template>
  <div>
    <v-form>
      <p class="mb-2">What is the main organic product of the reaction that is shown below?</p>

      <p class="mb-2 pl-12">
        <v-img src="/img/assignments/uottawa_task25_main.png" max-width="189px" />
      </p>

      <v-radio-group v-model="inputs.studentAnswer" :disabled="!allowEditing" class="mb-0">
        <v-radio
          v-for="option in optionsShuffled"
          class="my-3"
          :key="option.value"
          :value="option.value"
        >
          <template #label>
            <v-img :src="option.img" style="max-width: 63px" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUott1721M6_Task25en',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
      options: [
        {img: '/img/assignments/uottawa_task25_1.png', value: 'a'},
        {img: '/img/assignments/uottawa_task25_2.png', value: 'oneDeuterium'},
        {img: '/img/assignments/uottawa_task25_3.png', value: 'wrongDeuterium'},
        {img: '/img/assignments/uottawa_task25_4.png', value: 'noHydrogen'},
      ],
    };
  },
  computed: {
    image1Name() {
      return '/img/assignments/ChemUCI51LCPL3Q3_main.png';
    },
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
